/// slider
$(document).on('click', '.globalblue_v2 .go_to', function(e){
  e.preventDefault();

  var go_to = $(this).attr('data-goto');
  var cur = 0;

  var status_error = false;
  
  if(!$(this).hasClass('prev')) {
    if($(".form_global").valid() == false) {
      var currentPage = $('.globalblue_v2').attr('data-current');

      if($(currentPage).find('input.error').length > 0){
        status_error = true;
      }
    }
  }

  if(status_error == false) {
    $('input').removeClass('error');
    $('.error').hide();
    $('.basic-error').hide();
    $('.globalblue_v2').attr('data-current', go_to);

    $('.steps .step').removeClass('active');
    $('.steps .step').removeClass('done');

    $('.steps .step[data-goto="'+ go_to +'"]').addClass('active');

    $('.steps .step').each(function(){
      
      if($(this).attr('data-goto') == go_to) {
        return false;
      }

      $(this).addClass('done');
      cur++;
    });

    $('.line .inner').css('width', 25 * cur + '%');

    $('.step_box').hide();
    $('.step_box' + go_to).fadeIn();
  }
});

google.maps.event.addListener(autocomplete, 'place_changed', function() {
  var place = autocomplete.getPlace();
  if (place.place_id) {
    $('input[name="destination"]').val(place.formatted_address);
    var place_id = place.place_id;

    if(place.formatted_address > 0) {
      $('.destination_summary_row').show();
      $('.destination_value').html(place.formatted_address);
    } else {
      $('.destination_summary_row').hide();
    }

    boxes_and_calc();
  }
});

$(document).on('change', 'input[name="shipment_value"]', function(e){
  e.preventDefault();
  var value = $('input[name="shipment_value"]').val();
  
  if(value.length > 0) {
    $('.value_summary_row').show();
    $('.product_value').html('&euro;' + value);
  } else {
    $('.value_summary_row').hide();
  }

});

/// product amount calc 
$(document).on('click', '.globalblue_v2 .control.up', function(e){
  e.preventDefault();

  var input = $(this).parent().find('input');
  var value = input.val();

  input.val(parseInt(value) + 1);

  boxes_and_calc();
});

$(document).on('click', '.globalblue_v2 .control.down', function(e){
  e.preventDefault();

  var input = $(this).parent().find('input');
  var value = parseInt(input.val());
  
  if(value > 0) {
    input.val(value - 1);
  }

  boxes_and_calc();
});

$(document).on('change', '.globalblue_v2 .boxes .box .amount input', function(e){
  e.preventDefault();
  boxes_and_calc();
});

/// add Custom box
$(document).on('click', '.globalblue_v2 .btnAddBox', function(e){
  e.preventDefault();

  var l = $('.box-sizes input[name="xl"]').val();
  var w = $('.box-sizes input[name="xw"]').val();
  var h = $('.box-sizes input[name="xh"]').val();
  var weight = $('.box-sizes input[name="xweight"]').val();

  if(l > 0 & w > 0 & h > 0) {
    var title = 'Custombox ' + (parseInt($('.custom_boxes .box').length) + 1);
    var box = '';

    box += '<div class="box" data-name="'+ title +'" data-l="'+ l +'" data-w="'+ w +'" data-h="'+ h +'" data-weight="'+ weight +'"><div class="remove"><i class="fa fa-times"></i></div><div class="title">'+ title +'</div><span>' + l +'x'+ w +'x'+ h +'cm <br/><i>(' + (parseFloat(l) + 2) +'x'+ (parseFloat(w) + 2) +'x'+ (parseFloat(h) + 2) +'cm incl. packaging)</i></span></div>';

    $('.custom_boxes').append(box);
  }

  boxes_and_calc();
});

/// remove custom box 
$(document).on('click', '.globalblue_v2 .custom_boxes .remove', function(e){
  e.preventDefault();

  $(this).parent().remove();

  var n = 1;
  $('.globalblue_v2 .custom_boxes .box .title').each(function(){
    $(this).text('Custombox ' + n);
    n++;
  }); 

  boxes_and_calc();
}); 

/// set products list and calc price if possible
var boxes = [];
function boxes_and_calc() {
  boxes = [];
  var place = $('input#place_id').val();
  var url = $('.globalblue_v2').attr('data-url');
  var pageID = $('.globalblue_v2').attr('data-page');
  var n = 0;
  $('.boxes .box').each(function(){
    count = $(this).find('input').val();
    extra = parseInt($(this).attr('data-extra'));

    if(count > 0) {
      boxes[n] = {"name": $(this).attr('data-name'), "count": parseInt(count), "weight": $(this).attr('data-volume'), "length" : 0, "height" : 0, "width": 0, "extra_cost": extra};
    }
    
    n++;
  });

  $('.custom_boxes .box').each(function(){
    var l = parseFloat($(this).attr('data-l')) + 2;
    var w = parseFloat($(this).attr('data-w')) + 2;
    var h = parseFloat($(this).attr('data-h')) + 2;
    var weight = $(this).attr('data-weight');

    boxes[n] = {"name": $(this).attr('data-name'), "count": 1, "weight": weight, "length" : l, "height" : h, "width": w, "extra_cost": 0};
    
    n++;
  });

  if(place.length > 0 && boxes.length > 0) {
     $.each(boxes, function(k, v) {
      if($.isEmptyObject(v) == false) {
        
        $('.price_box p').hide();
        $('.price_box .products').hide();
        $('.price_box .lds-ellipsis').show();

        var packs = [];
        var data = {};
        var key = k;
        var count = v.count;
        data.length = v.length;
        data.width = v.width;
        data.height = v.height;
        data.weight = v.weight;
        data.extra_cost = v.extra_cost;
        data.count = 1;

        packs.push(data);

        $.ajax({
            type: "POST",
            url: url + '/ajax/call.php',
            data: {
              placeID: place,
              packs: packs,
              pageID: pageID,
              type: 'globalblue'
            },                
            success: function(val){
                console.log(val);

              if(val !== 'false') {
                  var data = jQuery.parseJSON(val);
                  var v = data.info;

                  $('select[name="billing_country"] option').each(function(){
                    var code = $(this).val();

                    if(code === v.country_code) {
                      $(this).prop('selected', true)
                    }
                  });

                  if(typeof v.region.length !== 'undefined'){

                    $('input[name="region"]').val(v.region);

                    if(v.region === 'Europe' || v.region === 'europe') {
                      tax = 0.21;

                      if(v.country == 'Switzerland') {
                        tax = 0;
                      } else if(v.country == 'Norway') {
                        tax = 0;
                      }

                      $('.globalblue_v2').attr('data-tax', tax);

                    } else {
                      $('.globalblue_v2').attr('data-tax', '0');
                      tax = 0;
                    }
                  }

                  console.log(v.courier_true);
                  if(typeof v.courier_true !== 'undefined'){
                    if(v.courier_true == 'false') {
                      $('.modal-koerier-big').show();
                    } else {
                      $('.modal-koerier-big').hide();
                    }
                  }

                  $.each(data, function(a, b) {
                    if(b.type == 'courier') {  
                      boxes[key].price = b.price * count;
                    }
                  });

                  render_totals(boxes);
              }
            }
        });
      }
     }); 
  }
}
//// shipment_value change calc Insurance
$(document).on("change", "input[name='add_insurance']", function () {
  var shipment_value = parseInt($('.global_calc input[name="shipment_value"]').val());
  var insurance = shipment_value * 0.02;
  
  if(shipment_value.length == 0 || isNaN(shipment_value)) {
    shipment_value = 0;
  }

  if ($('input[name="add_insurance"]').is(':checked')) {
    if(shipment_value < 350) {
      insurance = 0;
    } else if(shipment_value < 500){
        insurance = parseFloat(10).toFixed(2);
    } else {
      insurance = parseFloat(insurance).toFixed(2);
    }

    $('input[name="insurance"]').val(insurance);
  } else {
    $('input[name="insurance"]').val(0);
    $('.insurance-line').remove();
  }

  boxes_and_calc();
});

$('.global_calc input[name="shipment_value"]').keyup(function() {
  var shipment_value = parseInt($(this).val());
  var insurance = shipment_value * 0.02;
  var page = $('.globalblue_v2').attr('data-page');

  clearTimeout($.data(this, 'timer'));

  setTimeout(function() {
    if(shipment_value.length == 0 || isNaN(shipment_value)) {
      shipment_value = 0;
    }

    if(page == '30071') {
      if ($('input[name="add_insurance"]').is(':checked')) {
        if(shipment_value <= 350) {
          insurance = 0;
        } else if(shipment_value <= 500){
          insurance = parseFloat(10).toFixed(2);
        } else {
          insurance = parseFloat(insurance).toFixed(2);
        }

        $('input[name="insurance"]').val(insurance);

        boxes_and_calc();
      } else {
        $('input[name="insurance"]').val(0);
        $('.insurance-line').remove();
      }
    } else {
      if(shipment_value <= 350) {
        insurance = 0;
      } else if(shipment_value <= 500){
        insurance = 10;
      } else {
        insurance = parseFloat(insurance).toFixed(2);
      }

      $('input[name="insurance"]').val(insurance);

      boxes_and_calc();
    }
  }, 1000);

  $(this).data('timer', wait);
});

//// render totals
function render_totals(boxes) {
  var total = 0;
  var colli = 0;
  var lines = '';
  var packages = '';
  var packages_input = '';
  var tax = $('.globalblue_v2').attr('data-tax');

  $('.products .line').remove();

  $.each(boxes, function(k, v) {
    if($.isEmptyObject(v) == false) {
      if(v.price) {
        console.log(v);
        price = parseFloat(v.price).toFixed(2);
        total = total + parseFloat(v.price);
        packages += v.count + 'x ' + v.name + ', ';

        sizes = '';
        if(v.height > 0){
          sizes = ' - ' + v.length + 'x' + v.width + 'x' + v.height + 'cm';
        }

        packages_input += v.count + 'x ' + v.name + ' ('+ v.weight +'kg' + sizes + '), ';

        lines += '<div class="line"><div class="count">'+ v.count +'x</div><div class="name">'+ v.name +'</div><div class="price">&euro;'+ price +'</div></div>';  
        colli = parseFloat(colli) + parseFloat(v.count);
      }
    }
  });

  $('input[name="colli"]').val(colli);

  var insurance = $('.global_calc input[name="insurance"]').val();
  
  if(insurance.length == 0) {
    insurance = 0;    
  } else if(insurance == 0) {
    lines += '<div class="line insurance-line"><div class="name">Insurance</div><div class="price">Free</div></div>';
  } else if(insurance == 10) {
    lines += '<div class="line insurance-line"><div class="name">Insurance</div><div class="price">&euro;10.00</div></div>';
  } else {
    lines += '<div class="line insurance-line"><div class="name">Insurance</div><div class="price">&euro;'+ insurance +'</div></div>';
  }
  
  $('input[name="total_price"]').val(total.toFixed(2));

  var vat = (parseFloat(total) * tax);
  vat = vat.toFixed(2);
  
  total = parseFloat(vat) + parseFloat(total);
  total = parseFloat(total) + parseFloat(insurance);
  total = total.toFixed(2);

  if(total > 5000) {
    $('.total_to_high').show();
  }

  $('.vat').html('&euro;' + vat);
  $('.total_price').html('&euro;' + total);
  $(lines).insertBefore( ".products .tax");
  $('.products').show();
  $('.packages').html(packages.substring(0, packages.length - 2));
  
  $('input[name="items"]').val(packages_input.substring(0, packages_input.length - 2));

  if(packages.length > 0) {
    $('.package_summary_row').show();
  } else {
    $('.package_summary_row').hide();
  }

  $('.price_box .lds-ellipsis').hide();

  if($('input[name="add_insurance"]').length > 0 && $('input[name="add_insurance"]').prop('checked') != true) {
    console.log('123');
    $('.insurance-line').remove();
  }
}

/// Add document ID number 
var docN = 1;
$(document).on('click', '.addDocumentID', function(e){
  e.preventDefault();

  var doc = $('.documents .document.first').html();
  $('<div class="document x'+ docN +'"><div class="remove"><i class="fa fa-times"></i></div>' + doc + '</div>').insertAfter( ".documents .document_ids .document:last-child" );
   
  $('.document.x' +docN).find('input[name="document_ID"]').attr('name', 'document_ID_' + docN); 
  $('.document.x' +docN).find('textarea[name="document_items"]').attr('name', 'document_items_' + docN);  

  docN++;
});

$(document).on('click', '.document .remove', function(e){
  e.preventDefault();

  $(this).parent().remove();
  renderDocuments();
});

var documents = [];
$(document).on('change', '.documents .document_ids', function(e) {
   e.preventDefault();
   renderDocuments();
});

function renderDocuments() {
  documents = [];
  var content_summary = '';
  var document_summary = '';

  var n = 0;
  $('.documents .document_ids .document').each(function(){
     documents[n] = {"document_id": $(this).find('input').val(), "items": $(this).find('textarea').val()};

     if($(this).find('textarea').val().length > 0) {
       content_summary += $(this).find('textarea').val() + '<br/>';
     }
     if($(this).find('input').val().length > 0) {
       document_summary += $(this).find('input').val() + ', ';
     }
     n++;
  });

  if(document_summary.length > 0) {
   $('.document_summary_row').show();
  } else {
   $('.document_summary_row').hide();
  }

  if(content_summary.length > 0) {
   $('.content_summary_row').show();
  } else {
   $('.content_summary_row').hide();
  }

  $('.document_summary').html(document_summary.substring(0, document_summary.length - 2));
  $('.content_summary').html(content_summary);

  $('input[name="doc_ids"]').val(document_summary.substring(0, document_summary.length - 2));
  $('textarea[name="content"]').val(content_summary);
}

/// document other to summary
$(document).on('change', 'textarea[name="document_other"]', function(e){
  var other = $('textarea[name="document_other"]').val();
  
  if(other.length > 0) {
    $('.content_other_row').show();
    $('.content_other').html(other);
    $('textarea[name="without_content"]').val(other);
  }
});

$('.summery.leading').bind("DOMSubtreeModified",function(){
  var summery = $(this).html();
  $('.summery.copy').html(summery);
});

$(document).on('click', '.payment_box .tab', function(){
  $('.tab').removeClass('active');
  $(this).addClass('active');

  if($(this).hasClass('paylater')) {
    $('input#payment_method_PayLater').prop('checked', true);
    $('.payment_options.direct').hide();
    $('.payment_options.later').show();
  } else {
    $('.payment_options.later').hide();
    $('.payment_options.direct').show();
  }

});

$(".form_global").validate({
  focusCleanup: true,
  ignore: [],
  rules: {
    document_ID: {
      required: false,
      minlength: 20,
      maxlength: 20
    },
    document_ID_1: {
      required: false,
      minlength: 20,
      maxlength: 20
    },
    document_ID_2: {
      required: false,
      minlength: 20,
      maxlength: 20
    },
    document_ID_3: {
      required: false,
      minlength: 20,
      maxlength: 20
    },
    document_ID_4: {
      required: false,
      minlength: 20,
      maxlength: 20
    },
    document_ID_5: {
      required: false,
      minlength: 20,
      maxlength: 20
    }
  },
  invalidHandler: function(event, validator) {
    // 'this' refers to the form
    var errors = validator.numberOfInvalids();

    if (errors) {
      $(".basic-error").show();
    } else {
      $(".basic-error").hide();
    }
  }
});


$(".button_global").on('click', function(e){
  $('.loader_overlay').show();
  $(this).hide();
  if($('.form_global').valid() === false) {
    console.log('error');
    $('.loader_overlay').hide();
    $(this).show();
  }
});

$('.globalblue_v2 .addFile').click(function(){
  var cur = $('.files .row_field').length;
  var count = (parseInt($(this).attr('data-files')) + 1);
  var input = '';
  var i = cur;

  console.log(cur + ' -- ' + i);

  if(cur < 20) {
    while (i < count) {
      input += '<div class="row_field"><div class="label">File '+ count +'</div><input type="file" name="file_'+ count +'" value=""></div>';
      i++;
    }

    console.log(input);

    $(".globalblue_v2 .files").append(input);
    $(this).attr('data-files', count);

    $('.checkout_slider').css('height', $('.slide.inner.active').outerHeight());
  }
});

$('.globalblue_v2 .payment_method_pin').click(function(){
  $('.pay_cash').show();
});

$('.close_pay').click(function(){
  $('.pay_cash').hide();
});

$('.pay_cash input').keyup(function(){
  var pin = $(this).val();

  if(pin === $(this).attr('data-pass')) {
    $('.pay_cash').hide();
    $('.wc_payment_method').hide();
    $('#payment_method_cheque').prop('checked', true);
    $('.payment_method_pin').addClass('active').show();
    $('.paylater').hide();
    $('.txt').hide();
  }

});

$('.close_high').click(function(){
  $('.total_to_high').hide();
});

// Prevent the backspace key from navigating back.
$(document).unbind('keydown').bind('keydown', function (event) {
    if (event.keyCode === 8) {
        var doPrevent = true;
        var types = ["text", "password", "file", "search", "email", "number", "date", "color", "datetime", "datetime-local", "month", "range", "search", "tel", "time", "url", "week"];
        var d = $(event.srcElement || event.target);
        var disabled = d.prop("readonly") || d.prop("disabled");
        if (!disabled) {
            if (d[0].isContentEditable) {
                doPrevent = false;
            } else if (d.is("input")) {
                var type = d.attr("type");
                if (type) {
                    type = type.toLowerCase();
                }
                if (types.indexOf(type) > -1) {
                    doPrevent = false;
                }
            } else if (d.is("textarea")) {
                doPrevent = false;
            }
        }
        if (doPrevent) {
            event.preventDefault();
            return false;
        }
    }
});
